import React from "react";
import "./Squad.css";
import Kader from "../../Content/Pictures/svg_kader_sponsoren.jpg";

class Squad extends React.Component {
    render() {
        return (
            <div className="squad-div">
            <h2>SVG-Kader Saison 2020/21</h2>
                            <img
                                src={Kader}
                                className="squad-image"
                                height="800"
                                alt=""
                            />
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
                <h1>TEST</h1>
            </div>
        );
    }
}

export default Squad;
