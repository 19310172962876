import React, { Component } from "react";
import "./Footer.css";
import FooterMiddle from "./FooterMiddle.js";
import FooterTop from "./FooterTop.js";
import FooterBottom from "./FooterBottom.js";

class Footer extends Component {
    render() {
        return (
            <div className="footer-container">
                <FooterTop
                    onImpressumClicked={this.props.onImpressumClicked}
                    onPrivacyClicked={this.props.onPrivacyClicked}
                    onSquadClicked={this.props.onSquadClicked}
                    onRequestsClicked={this.props.onRequestsClicked}
                    onManagementClicked={this.props.onManagementClicked}
                    onEventsClicked={this.props.onEventsClicked}
                    onFirstTeamClicked={this.props.onFirstTeamClicked}
                    onSecondTeamClicked={this.props.onSecondTeamClicked}
                />
                <FooterMiddle />
                <FooterBottom />
            </div>
        );
    }
}

export default Footer;
