import React from "react";
import "./Management.css";
import dummy from "../../Content/Pictures/dummy.jpg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

class Management extends React.Component {
    render() {
        return (
            <div>
                <h2 className="management-header">SVG Vorstand</h2>
                <p className="management-descr">
                    {" "}
                    Der Vorstand des SV Großsteinhausen besteht aus dem 1.
                    Vorsitzenden, dem 2. Vorsitzenden und dem Schatzmeister
                    (Kassenwart). <br></br>Der Vorstand wird durch die
                    Mitgliederversammlung auf zwei Jahre gewählt.{" "}
                </p>
                <Container>
                    <Row className="management-row">
                        <Col className="management-col">
                            <Image className="management-img" src={dummy} alt=""/>{" "}
                        </Col>{" "}
                        <Col>
                            <img className="management-img" src={dummy} alt=""/>{" "}
                        </Col>{" "}
                        <Col>
                            <img className="management-img" src={dummy} alt=""/>{" "}
                        </Col>{" "}
                    </Row>
                    <Row>
                        {" "}
                        <Col>
                            {" "}
                            <div className="management-div">
                                {" "}
                                <p className="management-details">
                                    <b>Sebastian Baqué</b> <br></br> <br></br>
                                    1. Vorsitzender <br></br>seit 24.09.2021{" "}
                                    <br></br> <br></br>
                                </p>{" "}
                            </div>
                        </Col>{" "}
                        <Col> 

                            <div className="management-div">
                                {" "}
                                <p className="management-details">
                                    <b>Heiko Weber</b> <br></br> <br></br>
                                    2. Vorsitzender (Spielbetrieb)<br></br>seit 24.09.2021{" "}
                                    <br></br> <br></br>
                                </p>{" "}
                            </div>
            </Col> <Col>

                            <div className="management-div">
                                {" "}
                                <p className="management-details">
                                    <b>Julius Klotz</b> <br></br> <br></br>
                                    Schatzmeister (Kassenwart) <br></br>seit 24.09.2021{" "}
                                    <br></br> <br></br>
                                </p>{" "}
                            </div>
            </Col>{" "}
                    </Row>
                </Container>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            </div>
        );
    }
}
export default Management;
