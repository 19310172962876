import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Events.css";

class Events extends React.Component {
    render() {
        return (
            <div>
                {" "}
                <h2 className="events-header">SVG Jahrestermine 2021</h2>
                <Container className="events-container">
                    <Row className="events-row">
                        {" "}
                        <Col>
                            <h3 className="month-header">Januar</h3>{" "}
                            <p className="month-details">-------</p>
                        </Col>
                        <Col>
                            {" "}
                            <h3 className="month-header">Mai</h3>

                            <p className="month-details">-------</p>
                        </Col>
                        <Col>
                            {" "}
                            <h3 className="month-header">September</h3>
                            <p className="month-details">-------</p>
                        </Col>
                    </Row>
                    <Row className="events-row">
                        {" "}
                        <Col>
                            <h3 className="month-header">Februar</h3>{" "}
                            <p className="month-details">-------</p>
                        </Col>
                        <Col>
                            {" "}
                            <h3 className="month-header">Juni</h3>
                            <p className="month-details">-------</p>
                        </Col>
                        <Col>
                            {" "}
                            <h3 className="month-header">Oktober</h3>
                            <p className="month-details">Stenhauser Kerb 08. - 12.10.2021</p>
                        </Col>
                    </Row>
                    <Row className="events-row">
                        {" "}
                        <Col>
                            <h3 className="month-header">März</h3>{" "}
                            <p className="month-details">-------</p>
                        </Col>
                        <Col>
                            {" "}
                            <h3 className="month-header">Juli</h3>
                            <p className="month-details">Sportfest 15. - 20.07.2021</p>
                        </Col>
                        <Col>
                            {" "}
                            <h3 className="month-header">November</h3>
                            <p className="month-details">Bockbierfest 14.11.2021</p>
                        </Col>
                    </Row>
                    <Row className="events-row">
                        {" "}
                        <Col>
                            <h3 className="month-header">April</h3>{" "}
                            <p className="month-details">-------</p>
                        </Col>
                        <Col>
                            {" "}
                            <h3 className="month-header">August</h3>
                            <p className="month-details">-------</p>
                        </Col>
                        <Col>
                            {" "}
                            <h3 className="month-header">Dezember</h3>
                            <p className="month-details">Weihnachtsfeier 18.12.2021</p>
                        </Col>
                    </Row>
                </Container>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            <h1>FILLER</h1>
            </div>
        );
    }
}

export default Events;
