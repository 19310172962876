import React from "react";
import "./Privacy.css";
import Container from "react-bootstrap/Container";
class Privacy extends React.Component {
  render() {
    return (
      <div>
        <Container className="privacy">
          <h2>Datenschutz</h2>
          <p className="privacy-paragraph">
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe
            personenbezogener Daten möglich. Soweit auf unseren Seiten
            personenbezogene Daten (beispielsweise Name, Anschrift oder
            eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
            auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
            Zustimmung nicht an Dritte weitergegeben.<br></br> Wir weisen darauf
            hin, dass die Datenübertragung im Internet (z.B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich. Der Nutzung von im Rahmen der Impressumspflicht
            veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht
            ausdrücklich angeforderter Werbung und Informationsmaterialien wird
            hiermit ausdrücklich widersprochen. Die Betreiber der Seiten
            behalten sich ausdrücklich rechtliche Schritte im Falle der
            unverlangten Zusendung von Werbeinformationen, etwa durch
            Spam-Mails, vor.
          </p>
          <h3>
            Datenschutzerklärung für die Nutzung von Facebook-Plugins
            (Like-Button)
          </h3>
          <p className="privacy-paragraph">
            Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook,
            1601 South California Avenue, Palo Alto, CA 94304, USA integriert.
            Die Facebook- Plugins erkennen Sie an dem Facebook-Logo oder dem
            "Like-Button" ("Gefällt mir") auf unserer Seite. Eine Übersicht über
            die Facebook-Plugins finden Sie hier:<br></br>
            <a
              className="privacy-link"
              href="http://developers.facebook.com/docs/plugins/"
            >
              Facebook Plugins
            </a>
            <br></br> Wenn Sie unsere Seiten besuchen, wird über das Plugin eine
            direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server
            hergestellt. Facebook erhält dadurch die Information, dass Sie mit
            Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook
            "Like-Button" anklicken während Sie in Ihrem Facebook-Account
            eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem
            Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer
            Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir
            als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten
            Daten sowie deren Nutzung durch Facebook erhalten. Weitere
            Informationen hierzu finden Sie in der{" "}
            <a
              className="privacy-link"
              href="http://de-de.facebook.com/policy.php"
            >
              Datenschutzerklärung von facebook
            </a>
            . <br></br>
            Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten
            Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus
            Ihrem Facebook-Benutzerkonto aus.
          </p>
          <h3>Datenschutzerklärung für die Nutzung von Google Analytics</h3>
          <p className="privacy-paragraph">
            Diese Website benutzt Google Analytics, einen Webanalysedienst der
            Google Inc. ("Google"). Google Analytics verwendet sog. "Cookies",
            Textdateien, die auf Ihrem Computer gespeichert werden und die eine
            Analyse der Benutzung der Website durch Sie ermöglicht. Die durch
            den Cookie erzeugten Informationen über Ihre Benutzung dieser
            Website (einschließlich Ihrer IP-Adresse) wird an einen Server von
            Google in den USA übertragen und dort gespeichert.<br></br>
            Google wird diese Informationen benutzen, um Ihre Nutzung der
            Website auszuwerten, um Reports über die Websiteaktivitäten für die
            Websitebetreiber zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen
            zu erbringen. Auch wird Google diese Informationen gegebenenfalls an
            Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder
            soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google
            wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google
            Inc. in Verbindung bringen.<br></br>
            Sie können die Installation der Cookies durch eine entsprechende
            Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website voll umfänglich nutzen können. Durch die
            Nutzung dieser Website erklären Sie sich mit der Bearbeitung der
            über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art
            und Weise und zu dem zuvor benannten Zweck einverstanden.
          </p>
          <h3>Datenschutzerklärung für die Nutzung von Google Adsense</h3>
          <p className="privacy-paragraph">
            Diese Website benutzt Google AdSense, einen Dienst zum Einbinden von
            Werbeanzeigen der Google Inc. ("Google"). Google AdSense verwendet
            sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert
            werden und die eine Analyse der Benutzung der Website ermöglicht.
            Google AdSense verwendet auch so genannte Web Beacons (unsichtbare
            Grafiken). Durch diese Web Beacons können Informationen wie der
            Besucherverkehr auf diesen Seiten ausgewertet werden.<br></br>
            Die durch Cookies und Web Beacons erzeugten Informationen über die
            Benutzung dieser Website (einschließlich Ihrer IP-Adresse) und
            Auslieferung von Werbeformaten werden an einen Server von Google in
            den USA übertragen und dort gespeichert. Diese Informationen können
            von Google an Vertragspartner von Google weiter gegeben werden.
            Google wird Ihre IP-Adresse jedoch nicht mit anderen von Ihnen
            gespeicherten Daten zusammenführen.<br></br>
            Sie können die Installation der Cookies durch eine entsprechende
            Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website voll umfänglich nutzen können. Durch die
            Nutzung dieser Website erklären Sie sich mit der Bearbeitung der
            über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art
            und Weise und zu dem zuvor benannten Zweck einverstanden. <br></br>
            <br></br>Quellenangaben: eRecht24 Disclaimer, Facebook
            Datenschutzerklärung, Google Analytics Bedingungen, eRecht24
            Datenschutzerklärung Google Adsense
          </p>{" "}
        </Container>
        <h1>'</h1>
        <h1>'</h1>
        <h1>'</h1>
      </div>
    );
  }
}

export default Privacy;
