import React, { Component } from "react";
import Facebook from "../../Content/icons/facebook.png";
import "./FooterMiddle.css";
import Fußball from "../../Content/icons/fussballde.png";
import SWFV from "../../Content/icons/swfv.png";
import Whatsapp from "../../Content/icons/whatsapp.png";
import Kicker from "../../Content/icons/kicker.png";
import DFB from "../../Content/icons/dfb.png";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Logo from "../../Content/Pictures/logo_dark.png";

class FooterMiddle extends Component {
  render() {
    return (
      <Container className="footer-middle" fluid>
        <Row className="justify-content-md-center">
          <img alt="" src={Logo} width="230px" height="270px" className="logo" />
        </Row>
        <Row className="justify-content-md-center">
          <a
            href="https://www.facebook.com/SvGrosssteinhausen"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="facebook-image-footer"
              src={Facebook}
              alt=""
              title="SVG Facebookseite"
            />
          </a>
          <a
            href="https://chat.whatsapp.com/5icDDO2TFZOKiBggKKgcW0"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="whatsapp-image-footer"
              src={Whatsapp}
              alt=""
              title="SVG Whatsapp Info Gruppe"
            />
          </a>
          <a
            href="http://www.fussball.de/verein/sv-1930-grosssteinhausen-suedwest/-/id/00ES8GNBB0000074VV0AG08LVUPGND5I#!/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="fußball-image-footer"
              src={Fußball}
              alt=""
              title="Fußball.de Sv Großsteinhausen"
            />
          </a>

          <a
            href="https://www.swfv.de/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="swfv-image-footer"
              src={SWFV}
              alt=""
              title="Südwestdeutscher Fußballverband"
            />
          </a>

          <a
            href="https://www.kicker.de/sv-1930-grosssteinhausen-18031/info"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="fußball-image-footer"
              src={Kicker}
              alt=""
              title="Kicker SV Großsteinhausen"
            />
          </a>
          <a
            href="https://www.dfb.de/index/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="fußball-image-footer"
              src={DFB}
              alt=""
              title="Website Deutscher Fußball Bund"
            />
          </a>
        </Row>
      </Container>
    );
  }
}

export default FooterMiddle;
