import React, { Component } from "react";
import "./InitialComponent.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { OverlayTrigger, Popover } from "react-bootstrap";
import MatchesFirst from "../Content/data/matches_1.json";
import MatchesSecond from "../Content/data/matches_2.json";
import Scheierwaldfeschd from "../Content/files/scheierwaldfeschd.pdf";

const matchesFirst = MatchesFirst.first.matches;
const matchesSecond = MatchesSecond.second.matches;

/*const news2 = (
  <Popover className="svg-popover">
    <Popover.Content className="popover-content">
      <img src={Sportfest} width="575px" />
    </Popover.Content>
  </Popover>
);*/

const news1 = (
  <Popover className="svg-popover">
    <Popover.Title as="h2" className="popover-title">
      News
    </Popover.Title>
    <Popover.Content className="popover-content">
      Der SV Großsteinhausen stellt die Weichen für die kommende Spielzeit. Nachdem das Engagement mit Spielertrainer Jochen Brunner zum Saisonende im Einvernehmen auslaufen wird, kann mit dem 27-jährigen Maximilian Kochmann ein neuer Übungsleiter vorgestellt werden.<br></br>
      Für Kochmann, der von der Landesligamannschaft des SC 1919 Hauenstein an den Scheuerwald wechselt, ist es das erste Amt als alleinverantwortlicher Trainer seiner noch jungen Karriere.
      In seiner Laufbahn als aktiver Spieler machte sich der offensive Mittelfeldspieler vor allem bei der SG Eppenbrunn einen Namen.<br></br>
      In seinen insgesamt drei Jahren im Verein gelang im Jahr 2019 der Aufstieg in die Landesliga.
      In dieser Spielklasse kämpft Kochmann nun noch bis Juni in der Aufstiegsrunde aussichtsreich auf Platz 2 liegend um den Aufstieg in die Verbandsliga, ehe er mit vollem Engagement seine Tätigkeit in Großsteinhausen aufnimmt.<br></br>
      Mit seiner Ankunft endet nach dann drei Spielzeiten auch die Zeit von Jochen Brunner als Übungsleiter des SVG.
      Nach zwei sportlich schwierigen Spielzeiten gelang unter ihm in der laufenden Saison ein Leistungssprung, der optimistisch auf eine erneute Teilnahme an der A-Klasse im kommenden Jahr blicken lässt. Jochen Brunner danken wir an dieser Stelle schon jetzt für seinen Einsatz auf und neben dem Platz, seine Zuverlässigkeit und seine angenehme Art, mit der er am Scheuerwald auch nach seiner verbleibenden Zeit als Spielertrainer gern gesehener Gast bleiben wird.
    </Popover.Content>
  </Popover>
);
class InitialComponent extends Component {
  openEvent() {
    window.open(Scheierwaldfeschd);
  }
  componentWillMount() {
    let currentDate = new Date();
    var nextMatchFirst = {}, nextMatchSecond = {};
    if (matchesFirst.length > 0) {
      let futureMatchesFirst = matchesFirst.filter(function (match) {
        return new Date(match.date) > currentDate;
      });
      if (futureMatchesFirst.length > 0) {
        nextMatchFirst = futureMatchesFirst.reduce(function (prev, curr) {
          return prev.date < curr.date ? prev : curr;
        });
        nextMatchFirst.dateFormatted = this.formatDate(new Date(nextMatchFirst.date));
      }
    }
    if (matchesSecond.length > 0) {
      let futureMatchesSecond = matchesSecond.filter(function (match) {
        return new Date(match.date) > currentDate;
      });
      if (futureMatchesSecond.length > 0) {
        nextMatchSecond = futureMatchesSecond.reduce(function (prev, curr) {
          return prev.date < curr.date ? prev : curr;
        });
        nextMatchSecond.dateFormatted = this.formatDate(new Date(nextMatchSecond.date));
      }
    }

    this.setState({
      nextMatchFirst: nextMatchFirst,
      nextMatchSecond: nextMatchSecond,
    });
  }
  formatDate(date) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return date.toLocaleDateString("de-DE", options);
  }
  render() {
    return (
      <div>
        <Container className="news-container">
          <h2 className="header-games">Nächste Spiele: </h2>
          <div className="next-games">
            {" "}
            <Row>
              <Col className="match-col">
                <h3>
                  {this.state.nextMatchFirst.home} -{" "}
                  {this.state.nextMatchFirst.away}{" "}
                </h3>
                <h4>{this.state.nextMatchFirst.dateFormatted} </h4>{" "}
                <div class="text-center">
                  {" "}
                  <img
                    src={this.state.nextMatchFirst.iconHome}
                    className="match-logo float-none"
                    alt=""
                  />
                  <img
                    src={this.state.nextMatchFirst.iconAway}
                    className="match-logo float-none"
                    alt=""
                  />
                </div>
              </Col>
              <Col className="match-col">
                <h3>
                  {this.state.nextMatchSecond.home} -{" "}
                  {this.state.nextMatchSecond.away}{" "}
                </h3>
                <h4>{this.state.nextMatchSecond.dateFormatted} </h4>{" "}
                <div class="text-center">
                  {" "}
                  <img
                    src={this.state.nextMatchSecond.iconHome}
                    className="match-logo float-none"
                    alt=""
                  />
                  <img
                    src={this.state.nextMatchSecond.iconAway}
                    className="match-logo float-none"
                    alt=""
                  />
                </div>
              </Col>{" "}
            </Row>
            <Row>
              <h2 className="header-news">
                SVG-News<br></br> <h3>klicken für Details</h3>
              </h2>
            </Row>{" "}
            <Row>
              <Col>
                <button onClick={this.openEvent}>
                  <h3>1. Scheierwaldfeschd</h3>
                  <br></br>
                  Frühschoppen und Blasmusik am Vatertag
                </button>
              </Col>
              <Col>
                <OverlayTrigger trigger="click" placement="right" overlay={news1}>

                  <button>
                    <h3>Neuer SVG-Trainer ab Sommer</h3>
                    <br></br>Maximilian Kochmann übernimmt</button>
                </OverlayTrigger>
              </Col>
            </Row>{" "}
          </div>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
          <h1>FILLER</h1>
        </Container>
      </div>
    );
  }
}

export default InitialComponent;
