import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import "./FooterBottom.css";

class FooterBottom extends Component {
    render() {
        return (
            <Container className="footer-bottom" fluid>
                <h3 className="copyright">
                    {" "}
                    Copyright &copy; SV Großsteinhausen e.V.
                </h3>
            </Container>
        );
    }
}

export default FooterBottom;
