import React from "react";
import "./Request.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Erwachsene from "../../Content/Pictures/antrag_erwachsene.png";
import Familie from "../../Content/Pictures/antrag_familien.png";
import Jugendliche from "../../Content/Pictures/antrag_jugendliche.png";
import Image from "react-bootstrap/Image";
import ErwachsenePDF from "../../Content/files/Mitgliedsantrag_Erwachsene.pdf";
import FamilienPDF from "../../Content/files/Mitgliedsantrag_Familien.pdf";
import JugendlichePDF from "../../Content/files/Mitgliedsantrag_Jugendliche.pdf";

class Request extends React.Component {
    openRequest(request) {
        window.open(request);
    }
    render() {
        return (
            <div>
                <Container className="request-container">
                    <Row>
                        <div>
                            <h2 className="request-h2">SVG Mitgliedsanträge</h2>
                            <h3 className="request-h3">Zum Download klicken</h3>
                        </div>
                    </Row>
                    <br></br>
                    <Row className="second-row">
                        <Col className="request-col">
                            <p className="request-header">Erwachsene</p>
                            <Image
                                fluid="true"
                                src={Erwachsene}
                                className="request-img"
                                onClick={this.openRequest.bind(
                                    null,
                                    ErwachsenePDF
                                )}
                            />
                        </Col>
                        <Col className="request-col">
                            <p className="request-header">Familien</p>
                            <Image
                                fluid="true"
                                src={Familie}
                                className="request-img"
                                onClick={this.openRequest.bind(
                                    null,
                                    FamilienPDF
                                )}
                            />
                        </Col>
                        <Col className="request-col">
                            <p className="request-header">Jugendliche</p>
                            <Image
                                fluid="true"
                                src={Jugendliche}
                                className="request-img"
                                onClick={this.openRequest.bind(
                                    null,
                                    JugendlichePDF
                                )}
                            />
                        </Col>
                    </Row>
                </Container>
                <h1>FILLER</h1>
                <h1>FILLER</h1>
                <h1>FILLER</h1>
                <h1>FILLER</h1>
                <h1>FILLER</h1>
                <h1>FILLER</h1>
            </div>
        );
    }
}

export default Request;
