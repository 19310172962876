import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import SVGNavbar from "./Components/SVGNavbar";
import InitialContent from "./Components/InitialComponent";
import Header from "./Components/Header";
import Footer from "./Components/footer/Footer";
import Impressum from "./Components/info/Impressum";
import Privacy from "./Components/info/Privacy";
import Squad from "./Components/teams/Squad";
import FirstTeam from "./Components/teams/FirstTeam";
import SecondTeam from "./Components/teams/SecondTeam";
import Requests from "./Components/club/Request";
import Management from "./Components/club/Management";
import Events from "./Components/clubhouse/Events";

class App extends React.Component {
    componentDidMount() {
        ReactDOM.render(<Header />, document.getElementById("header"));
        ReactDOM.render(
            <SVGNavbar
                onImpressumClicked={this.renderImpressum}
                onPrivacyClicked={this.renderPrivacy}
                onHomeClicked={this.renderHome}
                onSquadClicked={this.renderSquad}
                onFirstTeamClicked={this.renderFirstTeam}
                onSecondTeamClicked={this.renderSecondTeam}
                onRequestsClicked={this.renderRequests}
                onManagementClicked={this.renderManagement}
                onEventsClicked={this.renderEvents}
            />,
            document.getElementById("navbar")
        );
        ReactDOM.render(<InitialContent />, document.getElementById("content"));
        ReactDOM.render(
            <Footer
                onImpressumClicked={this.renderImpressum}
                onPrivacyClicked={this.renderPrivacy}
                onSquadClicked={this.renderSquad}
                onFirstTeamClicked={this.renderFirstTeam}
                onRequestsClicked={this.renderRequests}
                onManagementClicked={this.renderManagement}
                onEventsClicked={this.renderEvents}
                onSecondTeamClicked={this.renderSecondTeam}
            />,
            document.getElementById("footer")
        );
    }
    render() {
        return (
            <div className="App">
                <div id="header" />
                <div id="navbar" />
                <div id="content" />
                <div id="footer" />
            </div>
        );
    }

    renderImpressum() {
        ReactDOM.render(<Impressum />, document.getElementById("content"));
    }
    renderHome() {
        ReactDOM.render(<InitialContent />, document.getElementById("content"));
    }
    renderPrivacy() {
        ReactDOM.render(<Privacy />, document.getElementById("content"));
    }
    renderSquad() {
        ReactDOM.render(<Squad />, document.getElementById("content"));
    }
    renderFirstTeam() {
        ReactDOM.render(<FirstTeam />, document.getElementById("content"));
    }
    renderSecondTeam() {
        ReactDOM.render(<SecondTeam />, document.getElementById("content"));
    }
    renderRequests() {
        ReactDOM.render(<Requests />, document.getElementById("content"));
    }
    renderManagement() {
        ReactDOM.render(<Management />, document.getElementById("content"));
    }
    renderEvents() {
        ReactDOM.render(<Events />, document.getElementById("content"));
    }
}

export default App;
