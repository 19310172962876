import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./SVGNavbar.css";
import Container from "react-bootstrap/Container";
import Logo from "../Content/Pictures/logo_dark.png";
import Hygienekonzept from "../Content/files/hygienekonzept.pdf";

function SVGNavbar(props) {
    const [showInfo, setShowInfo] = React.useState(false);
    const [showTeams, setShowTeams] = React.useState(false);
    const [showClub, setShowClub] = React.useState(false);
    const [showClubhouse, setShowClubhouse] = React.useState(false);
    const showDropdownInfo = (e) => {
        setShowInfo(!showInfo);
    };
    const hideDropdownInfo = (e) => {
        setShowInfo(false);
    };
    const showDropdownTeams = (e) => {
        setShowTeams(!showTeams);
    };
    const hideDropdownTeams = (e) => {
        setShowTeams(false);
    };
    const showDropdownClub = (g) => {
        setShowClub(!showClub);
    };
    const hideDropdownClub = (g) => {
        setShowClub(false);
    };
    const openHygienekonzept = () => {
        window.open(Hygienekonzept);
    };
    const showDropdownClubhouse = (g) => {
        setShowClubhouse(!showClubhouse);
    };
    const hideDropdownClubhouse = (g) => {
        setShowClubhouse(false);
    };
    return (
        <Navbar className="svg-nav">
            <Navbar.Brand href="#home" className="nav-brand" onClick={props.onHomeClicked}>
                <img alt="" src={Logo} width="230px" />{" "}
            </Navbar.Brand>
            <Container>
                <Nav.Link
                    href="#home"
                    className="nav-link-single"
                    onClick={props.onHomeClicked}
                >
                    HOME
                </Nav.Link>
                <NavDropdown
                    href="#verein"
                    title="VEREIN"
                    show={showClub}
                    onMouseEnter={showDropdownClub}
                    onMouseLeave={hideDropdownClub}
                >
                    {" "}
                    <NavDropdown.Item
                        className="nav-drop-item"
                        onClick={props.onManagementClicked}
                    >
                        Vorstand
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        className="nav-drop-item"
                        onClick={props.onRequestsClicked}
                    >
                        Mitgliedsanträge
                    </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                    title="TEAMS"
                    show={showTeams}
                    onMouseEnter={showDropdownTeams}
                    onMouseLeave={hideDropdownTeams}
                >
                    <NavDropdown.Item
                        className="nav-drop-item"
                        onClick={props.onSquadClicked}
                    >
                        Kader
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        className="nav-drop-item"
                        onClick={props.onFirstTeamClicked}
                    >
                        1. Mannschaft
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        className="nav-drop-item"
                        onClick={props.onSecondTeamClicked}
                    >
                        2. Mannschaft
                    </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="SPORTHEIM" show={showClubhouse} onMouseEnter={showDropdownClubhouse} onMouseLeave={hideDropdownClubhouse}>
        <NavDropdown.Item className="nav-drop-item" onClick={props.onEventsClicked}>Jahrestermine</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                    title="INFO"
                    className="nav-drop"
                    show={showInfo}
                    onMouseEnter={showDropdownInfo}
                    onMouseLeave={hideDropdownInfo}
                >
                    <NavDropdown.Item
                        className="nav-drop-item"
                        onClick={props.onImpressumClicked}
                    >
                        Impressum
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        className="nav-drop-item"
                        onClick={props.onPrivacyClicked}
                    >
                        Datenschutzerklärung
                    </NavDropdown.Item>
                    <NavDropdown.Item
                        className="nav-drop-item"
                        onClick={openHygienekonzept}
                    >
                        Hygienekonzept
                    </NavDropdown.Item>
                </NavDropdown>
            </Container>
        </Navbar>
    );
}

export default SVGNavbar;
