import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Facebook from "../Content/icons/facebook.png";
import Fußball from "../Content/icons/fussballde.png";
import Phone from "../Content/icons/telephone.png";
import Mail from "../Content/icons/mail.png";
import SWFV from "../Content/icons/swfv.png";
import DFB from "../Content/icons/dfb.png";
import Kicker from "../Content/icons/kicker.png";
import Whatsapp from "../Content/icons/whatsapp.png";
import Image from "react-bootstrap/Image";
import "./Header.css";
class Header extends React.Component {
  render() {
    return (
      <Container fluid="ture" className="svg-header">
        <Row>
          <Image className="telephone-image" src={Mail} />
          <p className="header-text">sv-grosssteinhausen.de</p>
          <Image className="telephone-image" src={Phone} />
          <p className="header-text">06339/650</p>
          <Col>
            <a
              href="https://www.dfb.de/index/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="dfb-image"
                src={DFB}
                alt=""
                title="Website Deutscher Fußballbund"
              />
            </a>
            <a
              href="https://www.kicker.de/sv-1930-grosssteinhausen-18031/info"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="fußball-image"
                src={Kicker}
                alt=""
                title="Kicker SV Großsteinhausen"
              />
            </a>
            <a
              href="https://www.swfv.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="swfv-image"
                src={SWFV}
                alt=""
                title="Südwestdeutscher Fußballverband"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://chat.whatsapp.com/5icDDO2TFZOKiBggKKgcW0"
            >
              <img
                className="whatsapp-image"
                src={Whatsapp}
                alt=""
                title="SVG Whatsapp Info Gruppe"
              />
            </a>
            <a
              href="http://www.fussball.de/verein/sv-1930-grosssteinhausen-suedwest/-/id/00ES8GNBB0000074VV0AG08LVUPGND5I#!/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="fußball-image"
                src={Fußball}
                alt=""
                title="Fußball.de Sv Großsteinhausen"
              />
            </a>
            <a
              href="https://www.facebook.com/SvGrosssteinhausen"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="facebook-image"
                src={Facebook}
                alt=""
                title="SVG Facebookseite"
              />
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Header;
