import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import "./FooterTop.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Hygienekonzept from "../../Content/files/hygienekonzept.pdf";

const street = "Ringstraße 14";
const adress = "66484 Großsteinhausen";
class FooterTop extends Component {
  render() {
    return (
      <Container className="footer-top" fluid>
        <Row className="sitemap-row justify-content-center" float="center">
          <Col className="sitemapHeader justify-content-center">Kontaktdaten</Col>
          <Col className="sitemapHeader">Verein</Col>
          <Col xs={{offset: 2}} className="sitemapHeader">Teams</Col>
          <Col className="sitemapHeader">Information</Col>
        </Row>
        <Row className="sitemap-row">
          <Col className="sitemapContent">
            <div className="test-div">
              <label className="sitemap-label" id="sitemap-label">
                {" "}
                Adresse:
              </label>
              <p>
                {street}
                <br></br>
                {adress}
              </p>
            </div>
            <div>
              <label className="sitemap-label" id="sitemap-label">
                {" "}
                Email:
              </label>
              <p>kontakt@sv-grosssteinhausen.de</p>
            </div>

            <div>
              <label className="sitemap-label" id="sitemap-label">
                {" "}
                Telefon:
              </label>
              <p>06339/650</p>
            </div>
          </Col>
          <Col className="sitemapContent">
            <a class="sitemapLink" onClick={this.props.onManagementClicked} href="javascript:void">Vorstand</a><br></br>
            <br></br> 
            Ausschuss<br></br>
            <br></br>
            <a class="sitemapLink" onClick={this.props.onRequestsClicked} href="javascript:void">Mitgliedsanträge</a>
          </Col>
          <Col xs={{offset: 2}} className="sitemapContent">
            <a
              class="sitemapLink"
              onClick={this.props.onSquadClicked}
              href="javascript:void"
            >
              Kader
            </a>
            <br></br>
            <br></br>
            <a class="sitemapLink" onClick={this.props.onFirstTeamClicked} href="javascript:void">1. Mannschaft</a><br></br>
            <br></br>

            <a class="sitemapLink" onClick={this.props.onSecondTeamClicked} href="javascript:void">2. Mannschaft</a>
          </Col>
          <Col className="sitemapContent">
            <a
              class="sitemapLink"
              onClick={this.props.onImpressumClicked}
              href="javascript:void"
            >
              Impressum
            </a>
            <br></br>
            <br></br>
            <a
              class="sitemapLink"
              onClick={this.props.onPrivacyClicked}
              href="javascript:void"
            >
              Datenschutzerklärung
            </a>
            <br></br>
            <br></br>
            <a class="sitemapLink" href={Hygienekonzept} target="_blank">
              Hygienekonzept
            </a>
        <br></br><br></br>
            <a class="sitemapLink" onClick={this.props.onEventsClicked} href="javascript:void">Jahrestermine</a>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FooterTop;
