import React from "react";
import "./FirstTeam.css";

class FirstTeam extends React.Component {
    componentDidMount() {
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.innerHTML =
            "_kik({widget: 'tabelle', token: '601128245d0cbad8140a1cf0', container: 'vereinsheim-tabelle'});";
        /* this.instance.appendChild(s);

        const s2 = document.createElement("script");
        s2.type = "text/javascript";
        s2.async = true;
        s2.innerHTML =
            "_kik({widget: 'spieltag',token: '601128245d0cbad8140a1cf0',container: 'vereinsheim-spieltag'});";
        this.instance.appendChild(s2); */
    }
    render() {
        return (
            <div>
                <section className="test-container">
                    <div className="team-container">
                        {" "}
                        <h2 className="team-header">SPIELTAG</h2>{" "}
                        <div
                            className="svg-matchday"
                            id="vereinsheim-spieltag"
                        ></div>
                        <div ref={(el) => (this.instance = el)} />
                    </div>
                    <div className="team-container">
                        <h2 className="team-header">TABELLE</h2>{" "}
                        <div
                            className="svg-table"
                            id="vereinsheim-tabelle"
                        ></div>
                        <div ref={(el) => (this.instance = el)} />
                    </div>
                </section>
                <h1>t</h1>
                <h1>t</h1>
                <h1>t</h1>
                <h1>t</h1>
                <h1>t</h1>
                <h1>t</h1>
                <h1>t</h1>
                <h1>t</h1>
                <h1>t</h1>
                <h1>t</h1>
                <h1>t</h1>
            </div>
        );
    }
}

export default FirstTeam;
